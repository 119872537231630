.form-sec {
  color: var(--txt-black);

  .heading {
    background-color: var(--card_bg);
    border-radius: 10px;
    margin-top: 130px;
    margin-bottom: 30px;

    h1 {
      padding: 15px;
      border-bottom: 1px solid var(--border_color);
      font-size: 18px;
      font-size: $font_family_2;
    }

    form {
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .input-fields {
        width: 100%;
        padding: 10px 30px 10px 15px;
        color: var(--txt-black);
        background-color: transparent;
        border-radius: 5px;
        border: 1px solid rgba(255, 254, 254, 0.68);
        outline: none;
        font-weight: 500;
        font-size: 14px;

        &::placeholder {
          color: var(--txt-black);
        }
      }
    }
  }

  .inner-form {
    display: flex;
    align-items: center;
    justify-content: center;

    form {
      width: 45%;
      background-color: var(--card_bg);
      border-radius: 10px;
      // margin-top: 60px;
  
      @media ($breckpoint_LG) {
        width: 60%;
      }

      @media ($breckpoint_MD) {
        width: 70%;
      }

      @media ($breckpoint_SM) {
        width: 85%;
        margin-top: 40px;
      }

      @media ($breckpoint_ESM) {
        width: 95%;
      }

      

      .form_header {
        padding: 20px;
        border-radius: 10px 10px 0px 0px;
        font-size: 22px;
        font-weight: 500;
        color: var(--primary-color);
        background-color: transparent;
        border-bottom: 3px solid var(--border_color);
        // background: -webkit-linear-gradient(top, rgb(43, 56, 146) 0%, rgba(43, 56, 146, 0.37) 100%);

        @media($breckpoint_SM){
          font-size: 20px;
        }

      }

      .fields {
        padding: 20px;
      }

    
    }
  }
}
.form-group {
  text-align: center;

  input {
    &::placeholder {
      color: var(--txt-black);
      opacity: .4;
    }
  }

  .button_form {
    width: 50%;
    background-color: var(--primary-color);
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 14px;
    color: var(--txt-white);
    text-decoration: none;
    transition: all 0.3s linear;
    border: 1px solid var(--primary-color);
    margin-top: 25px;
    font-weight: 500;

    &:hover {
      opacity: .8;
    }

    @media($breckpoint_SM) {
      width: 80%;
    }

    @media($breckpoint_ESM) {
      width: 100%;
    }
  }
}

.form-select {
  background-color: var(--body) !important;
}

.form-select:disabled {
  background-color: var(--body) !important;
}

.input__table_checkbox{
  width: 14px;
  height: 14px;
}