.home-sec {


  // new section-----

  .main-row {
    row-gap: 20px;
    --card-bg-dash: #fff;


    .kt_card {
      padding: 20px;
      border-radius: 20px;
      box-shadow: var(--box-shadow3);
      border: 0;
      height: 100%;

      .icon {
        h4 {
          padding: 10px 12px;
          display: inline-block;
          border-radius: 10px;
          border: 1px solid var(--border_color);
          color: var(--primary-color);
        }
      }

      h3 {
        font-size: 15px;
        margin-top: 10px;
        opacity: 0.8;
      }

      .balance {
        margin-top: 10px;
        box-shadow: var(--box-shadow2);
        padding: 10px;
        border-radius: 10px;

        h4 {
          font-size: 20px;
          margin-bottom: 0;
          color: var(--primary-color);
        }
      }


    }




    .first-card {
      background-color: var(--card-bg-dash);
      overflow: hidden;
      border-radius: 10px;
      padding: 20px;
      z-index: 1;
      transition: all 0.4s linear;


      .card-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0;
        background-color: transparent;
        padding: 0;
        border-radius: 0;
        height: 76px;

        .card-imgs {
          position: relative;
          z-index: 1;

          .icon {
            position: absolute;
            top: 15px;
            right: 5px;
            font-size: 35px;
            color: var(--primary-color);
            transition: all 0.4s linear;

          }
        }

        h4 {
          color: var(--txt-black);
          font-weight: 600;
          text-align: right;
        }

        p {
          margin-bottom: 0;
          text-transform: capitalize;
          font-size: 16px;
          color: var(--txt-black);
          opacity: 0.7;
        }
      }

      .card-body {
        padding: 0;

        .row {
          row-gap: 10px;
        }

        .card-sec-1 {
          background-color: var(--heading-new-bg);
          padding: 20px 10px;
          width: 100%;
          border-radius: 10px;
          height: 100%;

          p {
            margin-bottom: 0;
          }

        }

      }

      .card-footer {
        background-color: transparent;
        border: 0;
        padding: 0;
        margin-top: 20px;
        user-select: none;
      }

      &:hover {

        .card-imgs {
          .icon {
            top: 5px;
            right: 13px;
            transform: scale(0.9);

          }
        }

      }
    }

    // income-card section...

    .income-card {
      background-color: var(--card-bg-dash);
      overflow: hidden;
      border-radius: 10px;
      padding: 20px;
      z-index: 1;
      transition: all 0.4s linear;
      padding: 0;

      .heading-income-card {
        margin-bottom: 20px;
        text-transform: capitalize;
        position: relative;
        display: flex;
        align-items: center;
        padding: 10px;
        background-color: var(--heading-new-bg);
        color: var(--primary-color);

        .card-imgs {
          position: relative;
          z-index: 1;
          display: inline-block;

          .icon {
            position: absolute;
            top: 15px;
            right: 5px;
            font-size: 35px;
            transition: all 0.4s linear;

          }
        }
      }

      .card-header {
        background-color: transparent;
        padding: 0;
        border: 0;
        border-radius: 0;

        .react-tabs__tab-list {
          display: flex;
          align-items: baseline;
          column-gap: 10px;
          border-bottom: 0;

          .react-tabs__tab {
            background-color: var(--card-bg-dash);
            bottom: 0;
            border-radius: 0 !important;
            padding: 7px 15px;

            &::after {
              display: none !important;
            }
          }

          .react-tabs__tab--selected {
            background-color: var(--primary-color);
            color: #fff;
          }
        }

      }

      .card-body {
        padding: 10px 15px;


        .income-tabs-card {
          // border-top: 1px solid #ddd;
          padding-top: 10px;

          .tabs-table-sec {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #ddd;
            padding: 12px 10px;


            &:last-child {
              border-bottom: 0;
            }

            p {
              margin-bottom: 0;
              display: flex;
              align-items: center;

              .dots {
                display: inline-block;
                width: 20px;
                margin-right: 20px;
                height: 10px;
                background-color: var(--primary-color);
              }
            }



            .income-balance {
              color: var(--primary-color);
              font-weight: 600;
            }

          }
        }
      }

      &:hover {

        .card-imgs {
          .icon {
            top: 5px;
            right: 13px;
            transform: scale(0.9);

          }
        }

      }


    }

    // add user card

    .adduser-card {
      background-color: var(--card-bg-dash);
      overflow: hidden;
      border-radius: 10px;
      padding: 20px;
      z-index: 1;
      transition: all 0.4s linear;
      height: 100%;
      padding: 0;

      .heading-adduser-card {
        margin-bottom: 10px;
        text-transform: capitalize;
        position: relative;
        display: flex;
        align-items: center;
        padding: 10px;
        background-color: var(--heading-new-bg);
        color: var(--primary-color);


        .card-imgs {
          position: relative;
          z-index: 1;
          display: inline-block;

          .icon {
            position: absolute;
            top: 15px;
            right: 5px;
            font-size: 35px;
            transition: all 0.4s linear;

          }
        }
      }

      .card-body {
        padding: 10px 15px;

        .adduser-tabs-card {
          overflow-y: scroll;
          height: 254px;
          padding-right: 20px;

          .tabs-adduser-sec {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            border-bottom: 1px solid #dddddda3;
            padding: 5px 0;


            .tr-left {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .tr-img {
                width: 40px;
                height: 40px;
                overflow: hidden;
                margin-right: 20px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              p {
                margin-bottom: 0;
                font-size: 15px;

              }

              h5 {
                color: var(--primary-color);
                font-size: 16px;
                margin-bottom: 0;
              }
            }

            .tr-right {
              p {
                margin-bottom: 0;
                font-weight: 600;
                font-size: 14px;
                color: var(--txt-black);
                opacity: 0.7;
              }
            }



          }
        }

      }

      &:hover {

        .card-imgs {
          .icon {
            top: 5px;
            right: 13px;
            transform: scale(0.9);

          }
        }

      }

    }


   




  }



  .Outer_box {
    padding: 20px;
    border-radius: 20px;
    box-shadow: var(--box-shadow3);
    border: 0;
    height: 100%;
    margin: 20px 0;

    .user_infor {
      box-shadow: var(--box-shadow3);
      padding: 10px 15px;
      border: 0;
      background-color: transparent;
      border-radius: 20px;



      .card-header {
        background-color: transparent;
        border-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left_ds {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;

          span {
            border: 1px solid var(--border_color);
            display: inline-block;
            padding: 10px 10px;
            border-radius: 10px;
          }

          h3 {
            font-size: 16px;
            margin-bottom: 0;
          }
        }

        h4 {
          color: var(--primary-color);
        }
      }

      .card-body {


        .card-data-user {
          box-shadow: var(--box-shadow2);
          border-radius: 20px;
          padding: 10px;

          .card-sec-1 {
            width: 100%;
            padding: 20px 0;

            p {
              margin-bottom: 0;

              span {
                display: block;
                padding: 5px 10px;
                border-radius: 10px;
                color: var(--txt-white);
                margin-top: 10px;
              }
            }
          }


        }
      }

    }


    .add__user {
      padding: 10px 15px;

      .add_user-header {
        background-color: transparent;
        border-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;

      }

      .add-body {

        .adduser-tabs-card {
          overflow-y: scroll;
          height: 240px;
          padding: 10px;
          padding-right: 20px;

          .tabs-adduser-sec {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            border-bottom: 1px solid #dddddda3;
            padding: 5px 0;


            .tr-left {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .tr-img {
                width: 40px;
                height: 40px;
                overflow: hidden;
                margin-right: 20px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              p {
                margin-bottom: 0;
                font-size: 15px;

              }

              h5 {
                color: var(--primary-color);
                font-size: 16px;
                margin-bottom: 0;
              }
            }

            .tr-right {
              p {
                margin-bottom: 0;
                font-weight: 600;
                font-size: 14px;
                color: var(--txt-black);
                opacity: 0.7;
              }
            }



          }
        }
      }

    }



  }

   // Transactions History card working

   .transactions-card {
    overflow: hidden;
    border-radius: 20px;
    padding: 20px;
    transition: all 0.4s linear;
    height: 100%;
    padding: 20px;
    box-shadow: var(--box-shadow3);
    border: 0;


    .card-header {
      background-color: transparent;
      border-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left_ds {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;

        span {
          border: 1px solid var(--border_color);
          display: inline-block;
          padding: 10px 10px;
          border-radius: 10px;
        }

        h3 {
          font-size: 16px;
          margin-bottom: 0;
        }
      }

      h4 {
        color: var(--primary-color);
      }
    }
    


    .card-body {
      padding: 10px 15px;

      .table > :not(caption) > * > *{
        background-color: transparent;
      }

      .transactions-tabs-card {
        overflow-y: scroll;
        height: 254px;
        padding-right: 20px;
        box-shadow: var(--box-shadow2);
        border-radius: 10px;
        padding: 10px;

        .table {

          @media ($breckpoint_MD){
            width: 900px;
          }

          tr {
            td {
              vertical-align: middle;
              font-size: 15px;

              .tr-img {
                width: 40px;
                height: 40px;
                overflow: hidden;
                margin-right: 20px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              .tr-text {
                h5 {
                  margin-bottom: 0;
                }

                p {
                  margin-bottom: 0;
                }
              }

              .badge-span {
                display: inline-block;
                padding: 5px 10px;
                font-size: 14px;
                color: #fff;
                text-transform: capitalize;
                border-radius: 5px;

              }

              .debit {
                background-color: var(--bs-danger);
              }

              .credit {
                background-color: var(--bs-success);

              }

            }

            .blanace {
              color: var(--primary-color);

            }

            .badge-status {
              text-align: center;
            }

            .date {
              text-align: center;
            }

          }
        }
      }

    }

    &:hover {

      .card-imgs {
        .icon {
          top: 5px;
          right: 13px;
          transform: scale(0.9);

        }
      }

    }

  }






}