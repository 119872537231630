.main-form-page {
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
  width: 100%;
}

.main-form-page::after {
  position: absolute;
  background-image: url(../images/loginbg.png);
  content: "";
  height: 100%;
  z-index: -1;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.form-design {
  display: flex;
  justify-content: center;
}

.sub-title {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  background: white;
  padding: 50px;
  box-shadow: 0 0 35px rgb(3 192 232 / 10%);
  backdrop-filter: blur(10px);
  max-width: 500px;
  width: 100%;
  margin: auto;
  box-shadow: .5714285714rem .5714285714rem 1.1428571429rem 0 var(--shadow-down) inset, -.5714285714rem -.5714285714rem 1.1428571429rem 0 var(--shadow-up) inset;
  border-radius: 30px;

  @media ($breckpoint_SM) {
    margin: 0 30px;
    padding: 25px;
  }

  @media ($breckpoint_ESM) {
    margin: 0 15px;
    padding: 15px;
  }
}

.login-heading {
  text-align: center;
}

.login-heading img {
  height: 90px;
  margin: auto;
  width: auto;
}

.login-heading h4 {
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  font-family: $font_family_2;
  color: var(--txt-black);
}

.login-heading a {
  text-decoration: none;
}

.login-heading p {
  text-align: center;
  color: var(--txt-black);
}


.user-field {
  padding-bottom: 20px;
  position: relative;
}

.user-field i {
  position: absolute;
  top: 12px;
  font-size: 18px;
  right: 9px;
  color: var(--ferozi-color);
}

.password-field {
  padding-bottom: 20px;
  position: relative;
}

input {
  width: 100%;
  padding: 10px 30px 10px 15px;
  color: var(--txt-black);
  background-color: transparent;
  border-radius: 5px;
  // border: 1px solid rgba(0, 0, 0, 0.126);
  border: 1px solid transparent;
  outline: none;
  font-weight: 500;
  border-radius: 30px;
  font-size: 14px;
  box-shadow: 0 0 0 .1428571429rem #e1ffff00,.9285714286rem .9285714286rem 2.1428571429rem 0 #b4c1d500,-1.0714285714rem -1.0714285714rem 2.8571428571rem 0 #fff0,inset .5714285714rem .5714285714rem 1.1428571429rem 0 #b4c1d54f,inset -.5714285714rem -.5714285714rem 1.1428571429rem 0 #ffffff6e;
}

input::placeholder {
  color: var(--txt-black);
}

.password-field i {
  position: absolute;
  top: 12px;
  font-size: 18px;
  right: 9px;
  color: var(--ferozi-color);
}

.main-remember {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.remember-me {
  display: flex;
  align-items: center;
  gap: 7px;
}

.me-label {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: var(--txt-white);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--txt-black);
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: 0 0 0 .1428571429rem #e1ffff00,.9285714286rem .9285714286rem 2.1428571429rem 0 #b4c1d500,-1.0714285714rem -1.0714285714rem 2.8571428571rem 0 #fff0,inset .5714285714rem .5714285714rem 1.1428571429rem 0 #b4c1d54f,inset -.5714285714rem -.5714285714rem 1.1428571429rem 0 #ffffff6e;

}

.forget-pass a {
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: var(--txt-white);
}

.login-btn {
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;




  .button_login {
    width: 70%;
    background-color: var(--primary-color);
    padding: 10px 18px;
    border-radius: 30px;
    font-size: 14px;
    color: var(--txt-white);
    text-decoration: none;
    transition: all 0.3s linear;
    border: 2px solid transparent;

    &:hover {
      border-color: var(--primary-color);
      background-color: transparent;
      color: black;
    }
  }
}


.create-account {
  display: flex;
  justify-content: center;
}

.create-account a {
  text-decoration: none;
  color: var(--txt-black);
}