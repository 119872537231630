.edit__user {
    margin-bottom: 70px;

    .card {
        padding: 20px;
        border-radius: 20px;
        box-shadow: var(--box-shadow3);
        border: 0;
        height: 100%;

        .__editgroup {
            display: block;
            margin-bottom: 15px;

            label {
                margin-bottom: 8px;
                font-size: 15px;
                opacity: 0.8;
                padding-left: 10px;
            }

            select,
            input {
                border-radius: 20px;
                padding: 10px 15px;
                box-shadow: 0 0 0 0.1428571429rem rgba(225, 255, 255, 0), 0.9285714286rem 0.9285714286rem 2.1428571429rem 0 rgba(180, 193, 213, 0), -1.0714285714rem -1.0714285714rem 2.8571428571rem 0 rgba(255, 255, 255, 0), inset 0.5714285714rem 0.5714285714rem 1.1428571429rem 0 rgba(180, 193, 213, 0.3098039216), inset -0.5714285714rem -0.5714285714rem 1.1428571429rem 0 rgba(255, 255, 255, 0.431372549);
                border: 1px solid var(--border_color);

                &::placeholder {
                    color: #000;
                }

                &:focus {
                    box-shadow: unset !important;
                }

            }

            .react-tel-input .form-control {
                border: 1px solid var(--border_color) !important;

            }

            .react-tel-input .flag-dropdown {
                background-color: transparent;
                border: 0 !important;
                border-right: 1px solid #cacaca !important;
            }

            .css-t3ipsp-control:focus {
                box-sizing: 0 !important;
            }

            .css-13cymwt-control {
                padding: 5px 10px;
                z-index: 11;
                height: 100%;
                border-radius: 20px;
                box-shadow: 0 0 0 0.1428571429rem rgba(225, 255, 255, 0), 0.9285714286rem 0.9285714286rem 2.1428571429rem 0 rgba(180, 193, 213, 0), -1.0714285714rem -1.0714285714rem 2.8571428571rem 0 rgba(255, 255, 255, 0), inset 0.5714285714rem 0.5714285714rem 1.1428571429rem 0 rgba(180, 193, 213, 0.3098039216), inset -0.5714285714rem -0.5714285714rem 1.1428571429rem 0 rgba(255, 255, 255, 0.431372549);
                border: 1px solid var(--border_color);

            }

            .css-166bipr-Input,
            .css-1jqq78o-placeholder,
            .css-qbdosj-Input,
            .css-1dimb5e-singleValue {
                color: #000 !important;
            }

            .css-1n6sfyn-MenuList {
                padding: 10px 10px !important;
            }

            .css-t3ipsp-control {
                z-index: 11;
                border-radius: 20px;
                padding: 5px 10px;
                box-shadow: 0 0 0 0.1428571429rem rgba(225, 255, 255, 0), 0.9285714286rem 0.9285714286rem 2.1428571429rem 0 rgba(180, 193, 213, 0), -1.0714285714rem -1.0714285714rem 2.8571428571rem 0 rgba(255, 255, 255, 0), inset 0.5714285714rem 0.5714285714rem 1.1428571429rem 0 rgba(180, 193, 213, 0.3098039216), inset -0.5714285714rem -0.5714285714rem 1.1428571429rem 0 rgba(255, 255, 255, 0.431372549);
                border: 1px solid var(--border_color);


            }

            .css-1nmdiq5-menu {
                border: none !important;
                border-radius: 16px !important;
                padding: 0;
                z-index: 11;
                background-color: #fff;
            }

            .css-12zi2er-MenuList {
                &>div {
                    transition: all 0.3s linear;
                    cursor: pointer;
                    padding: 4px 14px;
                    border-radius: 5px;

                    &:hover {
                        background-color: var(--sidebar-bg);
                    }
                }
            }

            .css-1u9des2-indicatorSeparator {
                display: none !important;
            }

            .btn__submit {
                width: 40%;
                display: block;
                margin: 20px auto;
                padding: 10px 0;
                border-radius: 20px;
                color: var(--txt-white);
                background-color: var(--primary-color);
                transition: all 0.3s linear;

                &:hover {
                    background-color: transparent;
                    color: var(--primary-color);
                    border-color: var(--primary-color);
                }
            }
        }


        .react-tabs__tab-list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: center;
            border: 0;
            gap: 20px;
            margin-bottom: 20px;

            .react-tabs__tab {
                width: 100%;
                padding: 10px 10px;
                border: 0;
                bottom: 0;
                background-color: #eee;
                border-radius: 30px !important;
                color: var(--txt-black);


                &::after {
                    display: none;
                }
            }

            .credit_btn.react-tabs__tab--selected {
                background-color: var(--bs-success) !important;
                border: 1px solid var(--bs-success) !important;
                color: var(--txt-white);
            }

            .debit_btn.react-tabs__tab--selected {
                background-color: var(--bs-danger) !important;
                border: 1px solid var(--bs-danger) !important;
                color: var(--txt-white);

            }

            
        }

        .button_form{
            padding: 10px 30px;
            width: 70%;
            margin: 35px auto 20px;
            display: block;
            border-radius: 30px;
            color: var(--txt-white);
            transition: all 0.4s linear;

        }

        
        .debit_btn_bg {
            background-color: var(--bs-danger);
            border: 1px solid transparent;

            &:hover{
                background-color: transparent;
                border-color: var(--bs-danger);
                color: var(--bs-danger);
            }
        }


        .credit_btn_bg {
            background-color: var(--bs-success);
            border: 1px solid transparent;

            &:hover{
                background-color: transparent;
                border-color: var(--bs-success);
                color: var(--bs-success);
            }
        }
    }
}