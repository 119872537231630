// Language modal
$card__bg: #161a1e; // .card section
$modal_background: $card__bg; // all modalsssss
$color__green: #66c37b;
$sec_modal: #f5455b;
$txt-green: $color__green;
$txt-red: #f5455b;

@mixin display($justify-content, $align-items) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
}

.react-responsive-modal-modal {
  background-color: white !important;
  font-family: "Open Sans", sans-serif;
  // width: 100% !important;
  z-index: 99999999999 !important;
  color: var(--txt-black) !important;
  border: 1px solid #ededed1f;

  .card-header {
    border-bottom: 1px solid $primary-color;
    padding: 10px 0;
  }

  #Modal__details {
    // width: 500px;

    .modal-body {
      display: flex;
      flex-direction: column;

      .column__1 {
        display: flex;
        align-items: center;
        margin: 7px 0;

        p {
          flex-basis: 45%;
          padding: 3px;
          font-size: 16px;
          font-size: 900;
          color: var(--txt-black);
          padding: 7px 3px;
          margin: 0 10px;
          border-bottom: 0.5px solid #ededed21;
        }

        .Transaction_tokan {
          // fle
          word-break: break-all;
          white-space: normal;
        }

        .Transaction-fees {
          line-height: 13px;
        }

        .status_color_green {
          color: $color__green;
        }

        .status_color_red {
          color: $sec_modal;
        }
      }
    }

    .links {
      text-align: center;
      margin: 10px 0;
      padding-left: 10px;

      a {
        color: $sec_modal;
        outline: none;

        svg {
          margin-top: 10px;
          position: relative;
          top: 7px;
          width: 20px;
        }
      }
    }
  }
}

.Header__sidebar__right {
  width: 40% !important;
  max-width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  z-index: 9999999999999999999;
  background-color: var(--card_bg) !important;

  @media($breckpoint_MD){
    width: 50% !important;
  }

  @media($breckpoint_SM){
    width: 60% !important;
  }

  @media($breckpoint_ESM){
    width: 70% !important;
  }

  // overflow-y: hidden !important;
  .right_header-sidebar {
    ol {
      margin-top: 40px;
      padding: 0;

      li {
        position: relative;
        list-style-type: none;

        // overflow: hidden;
        .drop-link {
          color: var(--txt-black);
          text-decoration: none;
          width: 100%;
          display: block;
          font-size: 14px;
          box-shadow: unset;
          outline: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          background-color: transparent;
          padding: 10px;
          margin: 0 0 10px 0;
          border-radius: 6px;
          overflow: hidden;
          height: 45px;
          border: 1px solid $primary-color;

          .drop-link-data {
            @include display(left, center);

            .text-overflow {
              width: calc(300px - 50%);
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            svg {
              margin-right: 10px;
              transition: all 0.3s linear;
            }
          }

          svg {
            transition: all 0.3s linear;
          }

          .inner {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 20px;

            svg {
              transform: rotate(0deg);
              font-size: 20px;
            }

            .nft {
              fill: $primary-color;
            }
          }
        }

        .header_mobail {
          height: 0;
          transition: all 0.4s linear;
          overflow: hidden;

          .nav__dropa {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: column;
            padding: 0;
            margin: -10px 0 20px 0;

            .nav-link {
              background-color: transparent;
              padding: 10px;
              display: block;
              width: 100%;

              color: var(--txt-black);
            }
          }
        }

        .show {
          transition: all 0.1s linear;

          svg {
            transform: rotate(90deg);
          }
        }
      }

      .nav__item_dropw {
        .header_mobail {
          display: block;
          position: relative;
          margin: 10px 0;
        }
      }
    }
  }
  .inner_links {
    .accordion-item {
      background-color: transparent;
      border: none;
    }
    .accordion-button,
    .Responsive_link {
      display: block;
      padding: 12px;
      color: var(--txt-black);
      font-size: 14px;
      background-color: transparent;
      border-radius: 10px;
      position: relative;
      text-decoration: none;
      margin-top: 10px;
      font-weight: 500;
      &:hover {
        background-color: rgba(255, 255, 255, 0.04);
        // color: var(--primary-color);
      }
      &:focus {
        box-shadow: none;
        outline: none;
      }
      svg {
        position: absolute;
        top: 10px;
        right: 10px;
        transition: all 0.3s linear;
      }
    }
    .accordion-button:not(.collapsed) {
      background-color: rgba(255, 255, 255, 0.04);
      box-shadow: none;

      svg {
        rotate: 90deg;
      }
    }
    .accordion-body {
      padding-bottom: 0;
      ul {
        padding: 0;
        margin: 0;
        li {
          list-style-type: none;
          padding-left: 20px !important;
          position: relative;
          transition: all 0.3s linear;
          padding: 7px;
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          &:hover {
            padding-left: 40px !important;

            &::after {
              width: 30px;
            }

          }

          &::after {
            content: "";
            height: 2px;
            background-color: var(--primary-color);
            width: 12px;
            position: absolute;
            top: 17px;
            left: 0;
            transition: all 0.3s linear;
          }
        }
        a {
          text-decoration: none;
          font-size: 13px;
          font-weight: 500;
          color: var(--txt-black);

          &:hover{
            color: var(--primary-color);
          }
        }
      }
    }
  }
}
.react-responsive-modal-closeButton {
  top: 32px !important;
  right: 19px !important;
}
// mobile view header

@keyframes customEnterModalAnimation {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes customLeaveModalAnimation {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(100%);
  }
}

.Header__sidebar__right {
  .right_header-sidebar {
    h4 {
      color: var(--primary-color);
      padding: 15px;
      font-weight: 500;
      border-bottom: 1px solid var(--border_color);

      @media($breckpoint_SM){
        font-size: 18px;
      }
    }
  }

  .react-responsive-modal-closeButton {
    svg {
      fill: var(--txt-black);
    }
  }
}
.react-responsive-modal-overlay,
.Trading__coinLIst_overlay {
  background-color: rgba(31, 36, 48, 0.3) !important;
  backdrop-filter: blur(1px);
}

.react-responsive-modal-closeButton {
  svg {
    fill: var(--txt-black) !important;

    &:focus {
      border: none;
      outline: none;
    }
  }

  &:focus {
    border: none;
    outline: none;
  }
}
