.d-details-user {
    margin-bottom: 20px;

    .tabl-news-card {
        border-radius: 10px;
        background-color: #fff;
        box-shadow: 0px 0px 10px 0px #ddd;
        padding: 30px 20px;
        border-radius: 10px;

        .btn-news{
            background-color: var(--primary-color);
            color: var(--txt-white);
            padding: 10px 20px;
            width: 100%;
            display: block;
        }

        .nes-heading{
            font-size: 15px;
            color: #7c7c7c;
        }

        .news-card{
            background-color: var(--heading-new-bg);
            border: 1px solid #ddd;
            padding: 20px;
            
        }
    }


    .t-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 25px;

        .t-head-left{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 20px;

            h5{
                margin-bottom: 0;
                font-size: 18px;
            }
        }
    }

    .form-check{
        label{
            user-select: none;
            text-transform: capitalize;
            font-style: italic;
            margin-left: 10px;
        }
    }

    .add-news-button{
        background-color: var(--primary-color);
        color: var(--txt-white);
        padding: 10px 20px;
        border-radius: 10px;
        transition: all 0.4s linear;
        border: 1px solid transparent;
        text-transform: capitalize;

        &:hover{
            background-color: transparent;
            color: var(--primary-color);
            border-color: currentColor;
        }
    }

    .first-card {
        background-color: var(--card-bg-dash);
        overflow: hidden;
        border-radius: 10px;
        padding: 20px;
        z-index: 1;
        transition: all 0.4s linear;

        @media ($breckpoint_MD){
            margin-bottom: 20px;
        }
  
  
        .card-header {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border-bottom: 0;
          background-color: transparent;
          padding: 0;
          border-radius: 0;
          height: 76px;
  
          .card-imgs {
            position: relative;
            z-index: 1;
  
            .icon {
              position: absolute;
              top: 15px;
              right: 5px;
              font-size: 35px;
              color: var(--primary-color);
              transition: all 0.4s linear;
  
            }
          }
  
  
          p {
            margin-bottom: 0;
            text-transform: capitalize;
            font-size: 22px;
            color: var(--txt-black);
            opacity: 0.7;
          }
        }

        label{
            display: block;
            text-align: left;
        }

        .form-control{
            box-shadow: unset;
            border: 1px solid #ddd;
            border-radius: 4px;
            padding: 10px 10px;

            &:focus{
                border-color: var(--primary-color);
            }
        }
  
        .card-body {
          padding: 0;
  
          .card-sec-1 {
            background-color: var(--heading-new-bg);
            padding: 20px 10px;
            width: 100%;
            border-radius: 10px;
            height: 100%;


  
            p {
              margin-bottom: 0;
            }
  
          }
  
        }
  
        .card-footer {
          background-color: transparent;
          border: 0;
          padding: 0;
          margin-top: 20px;
          user-select: none;
        }
  
        &:hover {
  
          .card-imgs {
            .icon {
              top: 5px;
              right: 13px;
              transform: scale(0.9);
  
            }
          }
  
        }
      }

      .table{
        td{
            .news-sec{
                font-size: 20px;
            }
            border-bottom: 0;
        }
      }

}