@import "~bootstrap/scss/bootstrap";

@import "./variable.scss";
@import "./commen.scss";
@import "./sidebar.scss";

@import "./toggle.scss";

@import "./header.scss";
@import "./dashboard.scss";
@import "./modal.scss";
@import "./Login.scss";
@import "./table.scss";
@import "./form.scss";



@import "./reacttabs.scss";


@import "./news.scss";

@import "./viewUser.scss";

@import "./editUser.scss";


.react-tabs__tab{
    border-radius: 0 !important;
}