

.close__btn{
    outline: none;
    box-shadow: var(--box-shadow2);
    border: 1px solid #23232300;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    display: none;

    @media ($breckpoint_XL){
        display: inline-block;
    }
  
    .closeIcon{
      display: none;
    }
  }
  
  .show_header__mobail{
  
    .close__btn{
  
      .menuIcon{
        display: none;
      }
  
      .closeIcon{
        display: inline-block;
      }
    }
  
  }
  

.show_header__mobail{

    .sidebar-sec{
        left: 0;
    }

    .coustom_container{
        padding: 26px 40px 20px 40px;

        @media ($breckpoint_SM){
            padding:  15px 20px 15px;
          }
    }
}
