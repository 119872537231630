.sidebar-sec {
  width: 100%;
  background-color: var(--header-bg);
  position: fixed;
  top: 12px;
  left: 15px;
  bottom: 12px;
  width: 270px;
  height: 95vh;
  z-index: 99;
  overflow-y: scroll;
  border-radius: 20px;
  transition: all 0.4s linear;
  box-shadow: .7142857143rem .7142857143rem 1.4285714286rem 0 var(--shadow-down), -.7142857143rem -.7142857143rem 1.4285714286rem 0 #c8c4c436;

  @media ($breckpoint_XL){
    left: -270px;
    top: 0;
    height: 100vh;
    bottom: 0;
  }

  @media ($breckpoint_SM){
    border-radius: 0 !important;
  }





  .logo {
    padding: 10px;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #dce2ec;
    z-index: 2;
    overflow: hidden;


    a {
      text-decoration: none;
      color: var(--txt-black);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px ;
      border-radius: 20px;
      white-space: nowrap;
      overflow: hidden;

      span{
        transition: all 0.6s ease-in-out;
      }

    }

    img {
      width: 40px;
    }

    span {
      font-size: 20px;

    }
  }

  .links{
    padding: 20px 20px 50px;

    .accordion{

      .accordion-item{
        margin-bottom: 10px;
        border: 0 !important;

        &:first-child{

          button{
            a{
              box-shadow: unset !important;
              padding: 0 !important;
            }
          }
          .accordion-button::after{
            display: none;
            
          }
        }

        &:last-child{

          button{
            button{
              box-shadow: unset !important;
              padding: 0;
              width: 100%;
            }
          }
          .accordion-button::after{
            display: none;
            
          }
        }

        .accordion-button,
        button{
          border: 0;
          text-decoration: none;
          color: var(--txt-black);
          background-color: transparent;
          box-shadow: var(--box-shadow1);
          border-radius: 30px;
          font-size: 15px;
          padding: 12px 20px;
          display: block;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;

          span{
            transition: all 0.6s ease-in-out;
          }

          svg{
            margin-right: 5px;
            font-size: 19px;
          }

          &::after{
            position: absolute;
            right: 20px;
          }
        }

        .accordion-body{
          padding: 0;

          a{
            display: block;
            width: 100%;
            color: var(--txt-black);
            opacity: 0.9;
            text-decoration: none;
            padding: 10px 20px;
            font-size: 14px;

            svg{
              display: none;
            }
          }
        }
      }
    }
  }


}



.___sidebarOverly{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 98;
  background-color: #3c3c3a8f;
  display: none;
}


.header-section{
  position: fixed;

}


.show_header__mobail{

  
  .___sidebarOverly{

    @media ($breckpoint_LG){
      display: block;

    }
  

  }

}


// footer css









.footer_sec {
  background-color: var(--header-bg);
  border-top: 1px solid var(--border_color);
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 20px;
  z-index: 1;
  box-shadow: 16px 0px 16px rgba(24, 23, 38, 0.06);
  // border-radius: 10px;
  // margin-top: 40px;


  .inner_data {
    text-align: center;

    p {
      margin-bottom: 0px;

      a {
        text-decoration: none;
      }

      @media($breckpoint_SM) {
        font-size: 15px;
      }
    }
  }
}