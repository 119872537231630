@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

$font_family_1: "Ubuntu", sans-serif;
$font_family_2: "Montserrat", sans-serif;



//breakpoints
$breakpoint_xxl: "max-width:1700px";
$breckpoint_XXL: "max-width: 1400px";
$breckpoint_XL: "max-width: 1200px";
$breckpoint_LG: "max-width: 992px";
$breckpoint_MD: "max-width: 767px";
$breckpoint_SM: "max-width: 576px";
$breckpoint_ESM: "max-width: 450px";

$primary-color: var(--primary-color);



html {
  scroll-behavior: smooth;
}

body {
  scroll-behavior: smooth;
  background-color: var(--body);
  height: 100vh;
  font-family: $font_family_1;
}


main {
  height: 100vh;
  width: 100%;
}

.margin_top {
  margin-top: 130px;
  margin-bottom: 80px;

  @media($breckpoint_SM) {
    margin-top: 110px;
  }
}

.coustom_container {
  padding: 26px 40px 20px 330px;
  transition: all 0.4s linear;

  @media ($breckpoint_XL){
    padding: 26px 40px 20px 40px;
  }

  @media ($breckpoint_SM){
    padding:  15px 20px 15px;
  }
}

// page info css here

.page_info {
  width: 100%;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: var(--box-shadow1);
  border-radius: 20px;
  margin-bottom: 30px;

  @media ($breckpoint_ESM) {
    border-radius: 0;
    width: 100%;
  }

  .left__data{

    h6 {
      color: var(--primary-color);
      font-weight: 700;
      margin: 0;
      font-size: 1.6vw;
  
      @media ($breckpoint_SM) {
        font-size: 1.7vw;

      }
    }

    p{
      margin-bottom: 0;
    }
  }



  .right_data{

  }

}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--txt-white);
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232300;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.search_button {
  padding: 5px 10px;
  font-size: 13px;
  color: var(--txt-white);
  background-color: var(--primary-color);
  border: 1px solid transparent;
  border-radius: 5px;
  margin-left: 10px;
}

.input-fields {
  width: 100%;
  padding: 10px;
  color: var(--txt-black);
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid var(--border_color);
  outline: none;
  font-weight: 500;
  font-size: 14px;

  &::placeholder {
    color: var(--txt-white);
  }

  &:focus {
    border-color: var(--primary-color);
  }
}

.yellow_button {
  transition: all 0.3s linear;
  background-color: #f8b425 !important;

  &:hover {
    background-color: #e39c07 !important;
  }
}

.red_button {
  transition: all 0.3s linear;
  background-color: #ec4561 !important;

  &:hover {
    background-color: #e6183a !important;
  }
}

.pagination__2 {
  display: flex;
  gap: 8px;
  list-style: none;
  justify-content: center;
  margin-top: 30px;
  padding-bottom: 25px;


  @media($breckpoint_SM) {
    justify-content: center;
    margin-top: 20px;
  }

  @media($breckpoint_ESM) {
    gap: 5px;
  }


  .previous {
    a {
      border-radius: 50px 5px 5px 50px;
      padding: 8px 20px
    }
  }

  .next {
    a {
      border-radius: 5px 50px 50px 5px;
      padding: 8px 20px
    }
  }

  .active {

    a {
      background-color: var(--primary-color);
      color: var(--txt-white) !important;
    }

  }

  li {
    a {
      display: inline-block;
      background-color: transparent;
      box-shadow: var(--box-shadow3);
      border-radius: 5px;
      padding: 8px 13px;
      font-size: 15px;
      color: var(--txt-black) ;
      text-decoration: none;

      &:hover{
        background-color: #ddd;
      }

      @media($breckpoint_ESM) {
        padding: 5px 10px;
        font-size: 14px;
      }
    }
  }
}



.main_button {
  background-color: var(--primary-color);
  color: var(--txt-white);
  border-radius: 8px;
  border: 1px solid var(--primary-color);
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;

  &:hover {
    opacity: .8;
  }

}

.logout_button {
  padding: 7px 30px;
  font-size: 14px;
  color: var(--txt-white);
  background-color: var(--primary-color);
  border: 1px solid transparent;
  border-radius: 5px;
  margin-left: 15px;

  &:hover {
    border-color: var(--primary-color);
    background-color: transparent;
    color: white;
  }
}

.main_class {
  margin-bottom: 100px;
}

// box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);

.LoadingBar_contain {
  top: 0px !important;
  z-index: 98 !important;
  height: 8px !important;

}

.loader-bg {
  background-color: #ecececd6;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  backdrop-filter: blur(1px);
  transition: all 0.3s linear;

}


.news-sec {
  margin: 40px 0 0;

  .form-label {
    margin-bottom: 10px;
  }

  .form-control {
    background-color: transparent;
    border-color: var(--primary-color);
    color: var(--txt-black);
    border-radius: 10px;
    box-shadow: unset;
    padding: 12px 10px;
  }

  .submit-btn {
    width: 50%;
    display: block;
    margin: 40px auto 0;
    background-color: var(--primary-color);
    color: var(--txt-white);
    padding: 10px;
    border-radius: 40px;
    text-transform: capitalize;
  }

}

.no-data-js {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 50%;
    height: 50%;
  }
}


// ========================
// ========================
// ========================



.switch {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 65px;
  height: 30px;
  padding: 1px;
  border-radius: 3px;
  cursor: pointer;
}



.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 10px;
  text-transform: uppercase;
  background: var(--bs-danger);
  border-radius: inherit;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
  -webkit-transition: 0.15s ease-out;
  -moz-transition: 0.15s ease-out;
  -o-transition: 0.15s ease-out;
  transition: 0.15s ease-out;
  -webkit-transition-property: opacity background;
  -moz-transition-property: opacity background;
  -o-transition-property: opacity background;
  transition-property: opacity background;
}

.switch-label:before,
.switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
}

.switch-label:before {
  content: attr(data-off);
  right: 9px;
  color: #fff;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}

.switch-label:after {
  content: attr(data-on);
  left: 11px;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  opacity: 0;
}

.switch-input:checked~.switch-label {
  // background: var(--bs-danger);
  background: var(--bs-success);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
}

.switch-input:checked~.switch-label:before {
  opacity: 0;
}

.switch-input:checked~.switch-label:after {
  opacity: 1;
}

.switch-handle {
  position: absolute;
  top: 1px;
  left: -5px;
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 3px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  background-image: -webkit-linear-gradient(top, white 40%, #f0f0f0);
  background-image: -moz-linear-gradient(top, white 40%, #f0f0f0);
  background-image: -o-linear-gradient(top, white 40%, #f0f0f0);
  background-image: linear-gradient(to bottom, white 40%, #f0f0f0);
  -webkit-transition: left 0.15s ease-out;
  -moz-transition: left 0.15s ease-out;
  -o-transition: left 0.15s ease-out;
  transition: left 0.15s ease-out;
}

.switch-handle:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
  width: 12px;
  height: 12px;
  background: #f9f9f9;
  border-radius: 6px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
  background-image: -webkit-linear-gradient(top, #eeeeee, white);
  background-image: -moz-linear-gradient(top, #eeeeee, white);
  background-image: -o-linear-gradient(top, #eeeeee, white);
  background-image: linear-gradient(to bottom, #eeeeee, white);
}

.switch-input:checked~.switch-handle {
  left: 35px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

.switch-green>.switch-input:checked~.switch-label {
  background: #4fb845;
}


.buttons-s__nds{
  display: flex;
  align-items: center;
  gap: 10px;

}

.heading__new{
  @media ($breckpoint_MD){
    flex-direction:  column !important;
    align-items: flex-start !important;
  }

  .buttons-s__nds{
    @media ($breckpoint_MD){
      flex-direction: column;
      width: 100%;
      align-items: flex-start !important;
      padding: 0 10px;

      form{
        margin: 10px 0;
      }

      .btn__sdcsds{
        margin: 10px 0;
      }
    }
  }


  .btn__sdcsds{
    display: flex;
    align-items: center;
    justify-content: space-between;

  }
}

.Connect__wallet_button{
  padding: 8px 10px;
  background-color: var(--primary-color);
  box-shadow: unset;
  border: 1px solid transparent;
  transition: all 0.4s linear;
  color: var(--txt-white);
  border-radius: 10px;
  font-size: 14px;
  margin: 0 10px;
  white-space: nowrap;

  &:hover{
    background-color: transparent;
    color: var(--primary-color);
    border-color: currentColor;
  }
}


.badge{
  font-size: 12px;
  border-radius: 15px;
  display: block;
  margin: auto;
}