:root {
  --body: #fff;
  --txt-white: #fff;
  --txt-black: #000;
  --primary-color: #2b3892;
  --secondary-color:#31bafd;
  --border_color: rgb(227, 232, 239);
  --card_bg: #fff;
  --hover_bg: #eef2f6;
  // --hover_bg: #31bafd1f;
  --heading-new-bg:#dcebff;
  --red:#ff0000de;
  --green: green;

  
  --lightblue-color: #1ab1d1;
  --ferozi-color: #03c0e8;
  --field-color: #0d2d4f;
  --input-color: #83d3ff;
  --lightpurple-color: #9969ee;
  --header-bg: #fff;


  // kt...
  --shadow-up: #ffffff1c;
  --shadow-down: #b4c1d58f;
  --box-shadow1: .5714285714rem .5714285714rem 1.1428571429rem 0 var(--shadow-down) inset,-.5714285714rem -.5714285714rem 1.1428571429rem 0 var(--shadow-up) inset;
  --box-shadow2: .7142857143rem .7142857143rem 1.4285714286rem 0 var(--shadow-down) inset,-.7142857143rem -.7142857143rem 1.4285714286rem 0 var(--shadow-up) inset;
  --box-shadow3: .7142857143rem .7142857143rem 1.4285714286rem 0 var(--shadow-down),-.7142857143rem -.7142857143rem 1.4285714286rem 0 var(--shadow-up);
}
